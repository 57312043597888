import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ lang, meta, metaDescription, metaTitle }) {
  const { site, strapiCenturionGlobal } = useStaticQuery(
    graphql`
      query {
        strapiCenturionGlobal {
          meta_robots
        }
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  const description = metaDescription || site.siteMetadata.description
  const title = metaTitle || site.siteMetadata.title
  const robots = strapiCenturionGlobal.meta_robots || '';
  const metaTags = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
    {
      name: `google-site-verification`,
      content: `hk1S0N2bS-GSvjMphyZIYQVE2Pz4MyRhU9tPxtKfe8g`,
    },
  ].concat(meta);

  if (robots) {
    metaTags.push({
      name: 'robots',
      content: robots,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={metaTags}
    />
  )
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  metaDescription: PropTypes.string,
  metaTitle: PropTypes.string,
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  metaDescription: ``,
}

export default SEO
